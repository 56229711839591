import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle } from '../elements/Titles';
import TeamMemberOfTheMonthImages from '../components/TeamMemberOfTheMonthImages';

const TeamMemberOfTheMonth = () => (
  <Layout>
    <SEO title="Team Member of the Month" />
    <Container>
      <Row marginBottom="0">
        <Column>
          <LgTitle>Team Member of the Month</LgTitle>
        </Column>
      </Row>
      <TeamMemberOfTheMonthImages />
    </Container>
  </Layout>
);

export default TeamMemberOfTheMonth;
