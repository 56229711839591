import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

import {Column, Row} from '../../elements/Grid';
import {SmTitle} from '../../elements/Titles';
import TeamPlaceholder from '../TeamPlaceholder';
import {BaseParagraph, SmParagraph} from '../../elements/Paragraphs';
import {theme} from '../../../tailwind.config.js';
import {rem} from 'polished';

const TeamMemberOfTheMonthImages = () => {
    const data = useStaticQuery(graphql`
    query {
      imagePlaceHolder: file(relativePath: { eq: "placeholder_4.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwo: file(relativePath: { eq: "doug.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageThree: file(relativePath: { eq: "christine.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFour: file(relativePath: { eq: "jill.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFive: file(relativePath: { eq: "matt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSix: file(relativePath: { eq: "kevin.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeven: file(relativePath: { eq: "rudro.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEight: file(relativePath: { eq: "adelaide.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTen: file(relativePath: { eq: "mark.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwelve: file(relativePath: { eq: "bilal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFourteen: file(relativePath: { eq: "caitie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFifteen: file(relativePath: { eq: "shane.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSixteen: file(relativePath: { eq: "erica.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventeen: file(relativePath: { eq: "amanda_chin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEighteen: file(relativePath: { eq: "claire_kumagai.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNineteen: file(relativePath: { eq: "selina_winter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwenty: file(relativePath: { eq: "serah_louis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyOne: file(relativePath: { eq: "Ethan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyTwo: file(relativePath: { eq: "sigrid-Small.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyThree: file(relativePath: { eq: "niral.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyFour: file(relativePath: { eq: "katrina.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyFive: file(relativePath: { eq: "samantha.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageTwentyNine: file(relativePath: { eq: "cinzia.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortySeven: file(relativePath: { eq: "diana.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyOne: file(relativePath: { eq: "claire_bragonier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFiftyFive: file(relativePath: { eq: "jing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyNine: file(relativePath: { eq: "karen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyThree: file(relativePath: { eq: "jerry.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyTwo: file(relativePath: { eq: "brian.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageFortyEight: file(relativePath: { eq: "reshma.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightySeven: file(relativePath: { eq: "jolie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSeventyFive: file(relativePath: { eq: "steven.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinety: file(relativePath: { eq: "bethan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyFour: file(relativePath: { eq: "jason.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyThree: file(relativePath: { eq: "dylan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetySeven: file(relativePath: { eq: "marie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredNine: file(relativePath: { eq: "john_ostrowski.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEighty: file(relativePath: { eq: "evan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageNinetyTwo: file(relativePath: { eq: "dayle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageOneHundredTwelve: file(relativePath: { eq: "tyler.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageEightyNine: file(relativePath: { eq: "anna.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

    return (
        <>
            <Row>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">March</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageEightyNine.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Anna Szarzynski</BaseParagraph>
                    <SmParagraph marginBottom="16">HR Generalist</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Anna has been noticed by team members both internally AND externally for their positivity,
                        where candidates interviewing for roles with the company left positive Glassdoor reviews
                        about their experience with Wise Publishing!
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        She has played a key role in embracing the Three Amigos Productions, adding fun and creative
                        ideas to oscar-winning shorts.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Finally, on top of taking on and levelling up our formalized hiring process to be in line with
                        our values and competencies, she has played a key role in helping our leaders hire ten new team
                        members in the last two months!
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">February</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageOneHundredTwelve.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Tyler Wade</BaseParagraph>
                    <SmParagraph marginBottom="16">SEO Content Strategist</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Tyler has earned Employee of the Month for his exceptional contributions during the migration of
                        the XL websites to Money.ca. His competitive nature has been a driving force in his efforts to
                        continuously improve our web pages' rankings on Google.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        His determination to excel is matched by his creativity, as he is always brimming with
                        innovative ideas to enhance our digital presence. Beyond his professional skills, he is a pleasure
                        to work with. His positive attitude and willingness to collaborate have made him a valued member of our team.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">January</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageNinetyTwo.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Dayle McCool</BaseParagraph>
                    <SmParagraph marginBottom="16">QA Analyst</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Dayle has earned the title of Employee of the Month due to her remarkable contributions across
                        various projects and initiatives. Her proactive approach and willingness to go above and beyond
                        expectations have been evident in the quality of her work. Her dedication to streamlining QA process
                        through automation have not only set a high standard for excellence but have also been pivotal in our team’s success.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">December</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageTen.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Mark Villaflor</BaseParagraph>
                    <SmParagraph marginBottom="16">
                        Developer-Team Lead
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Mark has consistently demonstrated exceptional skills, dedication, and a collaborative spirit that greatly contributes to the success of our team.
                        As a Data Engineer Leader that keeps an smile on his face all the time and with profound expertise in our business and infrastructure Mark has
                        showcased an impressive ability to seamlessly integrate his technical skills into various projects also his collaborative nature and willingness
                        to go above and beyond to assist others sharing his knowledge, providing guidance, and contributing with innovative ideas. It is without a doubt
                        that Mark is a truly role mode and has made a substantial impact on our team's success.
                    </SmParagraph>
                </Column>
            </Row>
            <Row>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">November</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageEighty.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Evan Gowans</BaseParagraph>
                    <SmParagraph marginBottom="16">
                        Partnership Analyst
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Evan has embraced change in a very positive way and welcomed new opportunities and challenges (moving from PM to Editorial to Partnerships in a short period of time).
                        In his current role, he is proactively delivering insights and helping the Editorial and Partnerships team make smarter decisions based on data. He has helped us
                        increase our RPMV by helping the team optimize and send more traffic to our best performing landers.He is producing Monthly Recaps to help SLT better understand our
                        business. And he's working well as liaison between Editorial and Partnerships. "Analytics" is going to be an integral part of our company moving forward and Evan is a big piece of that.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">October</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageNinety.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Bethan Moorcraft</BaseParagraph>
                    <SmParagraph marginBottom="16">Staff Reporter</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Bethan spends each week knocking out hit after money-making hit on syndication — special
                        projects like the podcast or
                        video interviews don’t even slow her down. Quality is never in question, as she doesn’t step
                        away from the keyboard
                        until each story is clear, engaging and illuminating. You might expect this kind of relentless
                        workhorse to develop
                        a surly attitude over time, but Bethan is cherished at Wise for her cheerful professionalism and
                        a desire to become
                        better tomorrow than she is today.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">September</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageEightyFour.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Jason Lendrum</BaseParagraph>
                    <SmParagraph marginBottom="16">Senior Account Manager</SmParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageNinetyThree.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Dylan Shiffman</BaseParagraph>
                    <SmParagraph marginBottom="16">Account Manager</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Jason and Dylan are the faces of Wise Publishing to our partners. Since joining earlier this
                        year, they both jumped in
                        quickly to understand our business, our partner’s businesses and have taken ownership of their
                        respective categories.
                        Building deep relationships with our contacts and obsessively focusing on hitting their goals
                        has put us in a position
                        to unlock incremental budgets and higher payouts. At the same time, they have worked hard to
                        sign new contracts,
                        gain compliance approvals and onboard 40+ new partners via the XL Media Migration.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        And they’re fun.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">August</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageFortyTwo.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Brian Pacampara</BaseParagraph>
                    <SmParagraph marginBottom="16">Content Strategist</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Syndication saw a huge boost in August, with PVs rising 58% and revenue climbing 60%
                        month-over-month. This represents the hard
                        work of the entire editorial team (and many others outside of it) but it’s hard to ignore
                        Brian’s contributions. He’s been
                        championing the Cornerstone Project, working early mornings and weekends to ensure we have
                        adequate sends on MSN and
                        Apple as well as ideating stories for our in-house writers and freelancers that are the envy of
                        the industry. Keep up the
                        amazing work, Brian!
                    </SmParagraph>
                </Column>
            </Row>
            <Row marginBottom="144">
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">July</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageOneHundredNine.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Positive John Ostrowski</BaseParagraph>
                    <SmParagraph marginBottom="16">Director of Product</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Known to us all as PJ, John is a lighthouse of positivity. His meetings are not just meetings,
                        they
                        are dynamic and energetic events that keep us all on our toes and engaged. His focus on KPIs
                        results
                        keeps us accountable, driving us to continually test, optimize, and grow.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        PJ's commitment to Wise Publishing doesn't clock out at 5pm. For him, achieving results is
                        paramount,
                        doing whatever it takes to propel our brands forward. His dedication is contagious, setting the
                        pace
                        for the rest of us to follow.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        During our most recent acquisition, PJ worked tirelessly to ensure that both the development and
                        editorial
                        teams had all the support they needed to bring the project to the finish line. His is very
                        organized and,
                        and his ability to connect with team members and gather requirements is truly commendable. PJ's
                        role is
                        pivotal in our team's success, and we are fortunate to have such a dedicated leader among us.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">June</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageNineteen.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Selina Winter</BaseParagraph>
                    <SmParagraph marginBottom="16">Backend Developer</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Selina is an exceptional team member who consistently radiates positivity and embodies the core
                        values of
                        Support and Collaboration at Wise Publishing. Her unwavering commitment to these values is
                        evident in her
                        every interaction.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Selina’s remarkable passion and dedication are truly outstanding, as she approaches each task
                        and problem
                        with unwavering determination and takes complete ownership of her responsibilities. Even in the
                        face of
                        challenging conditions, Selina’s resilience shines through, and she consistently achieves
                        outstanding
                        results.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">May</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageFifteen.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Shane Murphy</BaseParagraph>
                    <SmParagraph marginBottom="16">
                        Manager of Email
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Shane's invaluable dedication to email has grown our subscriber list from 150,000 to almost
                        500,000, resulting
                        in a monthly revenue of 20k in GP. He consistently goes above and beyond the standard 9-5 to
                        ensure our readers
                        receive the best money email in their inboxes each morning.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Additionally, he has generously assisted the Editorial team in achieving their goals for the
                        past six weeks by
                        helping write performant landing pages that successfully meet our revenue targets. Shane's
                        exceptional multitasking
                        ability allows him to juggle a variety of tasks without losing his tailored and hand-crafted
                        approach to writing
                        our newsletters and landing pages, making his work truly exceptional.
                    </SmParagraph>
                    <SmParagraph marginBottom="8">
                        Furthermore, Shane's mind is filled with trivia knowledge. Beware of challenging him in Jeopardy
                        or Wordle... or
                        anything else with words.
                    </SmParagraph>
                </Column>
                <Column width="1/4">
                    <BaseParagraph marginBottom="8">April</BaseParagraph>
                    <Img
                        backgroundColor={theme.colors.purple['100']}
                        fluid={data.imageNinetySeven.childImageSharp.fluid}
                        style={{marginBottom: rem('24px')}}
                    />
                    <BaseParagraph marginBottom="8">Marie Alcober</BaseParagraph>
                    <SmParagraph marginBottom="16">Digital Editor</SmParagraph>
                    <SmParagraph marginBottom="8">
                        Marie came on board as a Digital Editor - but she's become so much more in such a short amount
                        of time. Throws and landers.
                        Video production. Podcast production. Editing help. She's been doing double-duty for us these
                        last couple of months helping
                        out with monetization and excelling. She's a shining example of what we're about here:
                        Dedication, perseverance, determination.
                        Marie is 'making it richer' for all of us!
                    </SmParagraph>
                </Column>
            </Row>
        </>
    );
};

export default TeamMemberOfTheMonthImages;
